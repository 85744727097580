import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
// import { msalConfig } from "./auth/authConfig";

import { ReactSession } from 'react-client-session';
const CryptoJS = require("crypto-js");
import axios from 'axios';
ReactSession.setStoreType("localStorage");
const RootUrl = process.env.REACT_APP_API_URL
const random = process.env.REACT_APP_SECRET_ID
// let session = ''
var requestId = CryptoJS.AES.encrypt(JSON.stringify(random), process.env.REACT_APP_KEY).toString();
let data = ''
let data1 = ''
let data2 = ''
let uri = ''
let clientId = ''
let redirecturi = ''

async function fetchFunction() {
  try{
	
   await axios.get(RootUrl+'/msal',
    {
      headers: {
        "requestId": requestId
      },
    })
            .then(response => {
              // console.log('response',response)
              data = response.data[0]
              data1 = response.data[1]
              data2 = response.data[2]
              uri = CryptoJS.AES.decrypt(data, process.env.REACT_APP_KEY);
              clientId = CryptoJS.AES.decrypt(data1, process.env.REACT_APP_KEY);
              redirecturi = CryptoJS.AES.decrypt(data2, process.env.REACT_APP_KEY);
              var decryptedauthority = JSON.parse(uri.toString(CryptoJS.enc.Utf8));
              var decryptedclientId = JSON.parse(clientId.toString(CryptoJS.enc.Utf8));
              var decryptedredirectUri = JSON.parse(redirecturi.toString(CryptoJS.enc.Utf8));
              // ReactSession.set("authority", decryptedauthority);
              // ReactSession.set("clientId", decryptedclientId );
              // ReactSession.set("redirectUri", decryptedredirectUri);
              // session =  ReactSession.get("authority")
              // session1 =  ReactSession.get("clientId")
              // session2 =  ReactSession.get("authority")
              console.log('config',session,session1,session2)
  
              // process.env.REACT_APP_AUTHORITY = decryptedData
  
              
            })
            .catch(error => {
            })
            const msalConfig = {
              auth: {
                // clientId: ReactSession.get("clientId"),
                // // clientId: CryptoJS.AES.decrypt(data1, process.env.REACT_APP_KEY),
                // authority: ReactSession.get("authority"), // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
                // redirectUri: ReactSession.get("redirectUri"),
            
            
                clientId: JSON.parse(clientId.toString(CryptoJS.enc.Utf8)),
                authority: JSON.parse(uri.toString(CryptoJS.enc.Utf8)), // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
                redirectUri: JSON.parse(redirecturi.toString(CryptoJS.enc.Utf8)),
                // authority: 'https://login.microsoftonline.com/common', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
                // redirectUri: 'https://manager-handbook-qa.abi-upscale.com/',
              },
              cache: {
                cacheLocation: 'sessionStorage', // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
              },
            }
  const msalInstance = new PublicClientApplication(msalConfig);
  
  ReactDOM.render(
    <React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <App />
    </Provider>
    </MsalProvider>
      </React.StrictMode>,
    document.getElementById('root'),
  )
  
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
  
  }
  catch(err) {
    throw err;
    console.log(err);
  }
}
 
fetchFunction()