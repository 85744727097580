import React, { Component, Suspense, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import './scss/_custom.scss'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react'
// import { loginRequest } from './auth/authConfig'
import { callMsGraph } from "./graph";
import axios from 'axios';
//import styled from 'styled-components';
import { ReactSession } from 'react-client-session';


ReactSession.setStoreType("localStorage");
const RootUrl = process.env.REACT_APP_API_URL
const CryptoJS = require("crypto-js");

// msal()
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const AbiLayout = React.lazy(() => import('./layout/AbiLayout'))
const AbiLayoutUnauth = React.lazy(() => import('./layout/AbiLayoutUnauth'))



// User Information  From MSAL
// function ProfileContent() {
//   const { instance, accounts } = useMsal();
//   const [graphData, setGraphData] = useState(null);

//   const name = accounts[0] && accounts[0].name;

//   function RequestProfileData() {
//       const request = {
//           ...loginRequest,
//           account: accounts[0]
//       };

//       // Silently acquires an access token which is then attached to a request for Microsoft Graph data
//       instance.acquireTokenSilent(request).then((response) => {
//         setAccessToken(response.accessToken) 
//         const {token, setAccessToken} = useState(null)
//         console.log('token', token);
//     }).catch((e) => {
//         instance.acquireTokenPopup(request).then((response) => {
//             setAccessToken(response.accessToken);
//         });
//     });
//   }
// };

// function RequestAccessToken() {
//   const {  accounts, instance ,inProgress  } = useMsal();
//   const [accessToken, setAccessToken] = useState(null);
//   const name = accounts[0] && accounts[0].name;
//   const request = {
//       ...loginRequest,
//       account: accounts[0]
//   };

//   // Silently acquires an access token which is then attached to a request for Microsoft Graph data
//   instance.acquireTokenSilent(request).then((response) => {
//       setAccessToken(response.accessToken);
//   }).catch((e) => {
//       instance.acquireTokenPopup(request).then((response) => {
//           setAccessToken(response.accessToken);
//       });
//   });
// }
// End Of User Information From MSAL
//Auth Login By MSAl
function  HandleLogin (instance)  {
  // console.log('handlelogin')
  const msalInstance = instance;
   msalInstance.handleRedirectPromise()
  .then((tokenResponse) => {
      if (!tokenResponse) {
          const accounts = msalInstance.getAllAccounts();
          if (accounts.length === 0 || !accounts) {
            //  console.log('accounts', accounts)
              // No user signed in
              msalInstance.loginRedirect();
          }
      } else {
          // Do something with the tokenResponse
      }
  })
  .catch(err => {
      // Handle error
      console.error(err);
  });
}
//End Of Auth 
function HitMe(user, setUser){
  // console.log('heit ')
  const rr = ''
  const {  accounts} = useMsal();
  const [resPonse, setResPonse] = useState(null);


  React.useEffect(() => {
    if(!user){
      //  console.log('outside_user', user)
      //  console.log('inside_user', accounts)
      if(accounts.length){
       let user =  accounts[0]
       const tenantId  = user.tenantId
       const username = user.username
       const fullname = user.name
       const requestId = CryptoJS.AES.encrypt(JSON.stringify(tenantId), process.env.REACT_APP_KEY).toString();
      //  const random = process.env.SECRET_ID
        // console.log('username',username)
        axios.post(RootUrl+'/users/auto_add_new', {user,requestId,username,tenantId,fullname })
          .then((response )=> {
            // console.log('response',response)
            // rr = response
            // setResPonse(response)
            // if (response.status == 202 || response.status == 201){

            //   let user = accounts[0]
            //   // const random  = user.tenantId
            //   // var requestId = CryptoJS.AES.encrypt(JSON.stringify(random), process.env.REACT_APP_KEY).toString();
            //   // console.log('response', requestId)
            //   axios.post(RootUrl+`/auth/proxy/`, { user,requestId,username,tenantId}
            //   )
            //   .then(response =>{
            //   //  console.log('response', response)
            //   ReactSession.set("token", response.data.token);
            //   setUser(response.data.user)
            // })
            // .catch(error => 
              
            //   {
            //     // window.location.reload();
            //   })
            // }
            // else{
            //   setErrorMessage('something went wrong')
            // }
              // }
            
            // console.log('rr',rr)
          }
            // console.log('response',response)
            
          )
          .catch(error => {
          });
      }
    }
  }, [user]);
  // console.log('response',resPonse)
  React.useEffect(() => {
    if(accounts.length != 0){
      if(!user){
        let user = accounts[0]
       const tenantId  = user.tenantId
        const username = user.username
        var requestId = CryptoJS.AES.encrypt(JSON.stringify(tenantId), process.env.REACT_APP_KEY).toString();
        // console.log('response', requestId)
        axios.post(RootUrl+`/auth/proxy/`, { user,requestId,username,tenantId }
        )
        .then(response =>{
        //  console.log('response', response)
        ReactSession.set("token", response.data.token);
        setUser(response.data.user)
      })
      .catch(error => 
        
        {
          window.location.reload();loca
        })
      }
    }

  }, [accounts]);
 

}
function App() {
  const { instance } = useMsal();
  const [user, setUser] = useState();
  const [errorMessage, setErrorMessage] = useState();
  
    let pathname = window.location.pathname
    // Azure_key_vault();
  
  //  console.log('header',pathname)
    return (
      <>
        {/* <div>
          <PageLayout>
            <AuthenticatedTemplate>
              <p>You are signed in!</p>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>{() => handleLogin(useMsal)}</UnauthenticatedTemplate>
          </PageLayout>
        </div> */}
        <BrowserRouter>
          <Suspense fallback={loading}>
          <UnauthenticatedTemplate>
            {() => HandleLogin(instance)}
          </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              {HitMe(user, setUser)}
              
              {
              user? 
              user.role_id == 2? 
              user.status == "pending"?
              
              <Routes>
                
              <Route path="*" name="" element={<AbiLayoutUnauth />} />
              <Route path="/home" name="" element={<AbiLayoutUnauth />} />
              </Routes>
              :
              <Routes>
              <Route path="*" name="" element={<AbiLayout />} />
              <Route path="" name="" element={<AbiLayout />} />
              </Routes>
             
              :
              <Routes>
               <Route  path="/admin/*" name="Dashboard" element={<DefaultLayout />} > </Route>
               <Route path="*" name="" element={<AbiLayout />} />
               <Route path="" name="" element={<AbiLayout />} />
             </Routes>
              : ""
              }
             
              

            </AuthenticatedTemplate>
            {/* <Routes>
              <Route  path="/admin/*" name="Dashboard" element={<DefaultLayout />} >
              </Route>
            </Routes>
            <Routes>
            <Route path="*" name="" element={<AbiLayout />} />  
            </Routes> */}
          </Suspense>
        </BrowserRouter>
      </>
    )
  
}

export default App
